<script lang="ts" setup>
// @cms-next src/pages/account/SetPasswordView.vue
import {computed, ref, watch} from 'vue'
import {ResetPasswordForm} from '@stqry/tix-reset-password-form'

import {altUnAuthFormBgColor} from '@/helpers/ui'
import {login} from '@/helpers/api'
import {runPostLoginTask} from '@/helpers/login'
import {NotFoundError} from '@/api/error/Misc'

interface Props {
  email: string
  mode: 'confirm-account' | 'reset-password'
  userType: 'staff' | 'tix'
  token: string
}

const props = defineProps<Props>()
const newPassword = ref('')
const error = ref('')

const passwordModeHeader = computed(() => (props.mode === 'confirm-account' ? 'Confirm account' : 'Create new password'))
const buttonLabel = computed(() => (props.mode === 'confirm-account' ? 'Confirm and Log In' : 'Update Password'))

watch(newPassword, v => {
  const data = {token: props.token, password: v}
  window.APIService.post('password_reset/reset', data)
    .then(() => {
      const authMethod = props.userType === 'staff' ? 'ticketure_staff' : 'ticketure'
      login(props.email, v, authMethod, null, true).then(() => {
        runPostLoginTask(true, document.getElementsByClassName('.password-form .button')[0] as HTMLButtonElement)
      })
    })
    .catch(err => {
      if (err instanceof NotFoundError) {
        error.value = 'There was an error processing your request. Please check your link.'
      } else {
        error.value = err
      }
    })
})

document.body.style.setProperty('--unauth-bg-color', altUnAuthFormBgColor)
</script>

<template>
  <h2 class="password-form-header">{{ passwordModeHeader }}</h2>
  <div v-if="error" class="WarningBlockRed">{{ error }}</div>
  <ResetPasswordForm :email="props.email" :buttonLabel="buttonLabel" v-model="newPassword" />
</template>

<style lang="scss">
h2.password-form-header {
  font-family: 'proxima-nova', helvetica, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #fff !important;
  margin-top: 0px;
  padding-top: 0px;
}

.password-form {
  --primary-red: #ff8697;
  --status-danger-border-color: #ff3552;
  --dark-grey: #bfc1ca;
  --medium-grey: #bfc1ca;
  --primary-blue: #008deb;
  --status-danger-text-color: #ff8697;

  aside.icon {
    --status-danger-text-color: #ff3552;
    --status-success-text-color: #6dbf13;
  }

  --status-success-text-color: #a7d971;

  input {
    --dark-grey: #000;
  }

  .invalid input {
    border-color: var(--primary-red) !important;
  }

  .button {
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    font-family: 'proxima-nova', helvetica, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;

    &:hover {
      background-color: #0093f5 !important;
    }
  }

  .password-requirements {
    border-radius: 4px;
    --light-blue1: rgba(255, 255, 255, 0.2);
    padding: 16px;
    > ul {
      padding-left: 16px;
    }

    > aside.icon {
      display: none;
    }

    h3 {
      color: #bfc1ca;
    }
  }

  li {
    --dark-grey: #fff;
  }
}
</style>

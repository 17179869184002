<script setup lang="ts">
// @cms-next /pages/Settings/Config/ListPage.vue
import {computed, reactive, ref} from 'vue'
import type {Dict} from '@/helpers/types'
import {configItemPath, getConfigValuesAndInitializeTemplates, getResourceID} from '@/config/helpers'
import type {ConfigItemProps} from '@/config/ConfigListItem.vue'
import ConfigListItem from '@/config/ConfigListItem.vue'
import type {ResourceName} from '@/api/response'
import {getMatchingTemplates} from '@/config/templatesStore'

// TODO Add automated testing for the new config UI;
//
// - Portal config & Seller config
// - List
//   - With no permissions
//   - With read-only permissions
//   - Hide values
//   - Hide unconfigured items
//   - Search; partial-start match, case insensitive, multiple words are ANDed
//   - Collapsible groups
// - Delete from list view
//   - UI updates immediately
//   - Change persists after reload
//   - Read-only items cannot be deleted
// - Detail
//   - With no permissions
//   - With read-only permissions (disabled forms)
//   - Configured and unconfigured (edit and add)
//   - Cannot save with read-only permissions
//   - Pristine field with default value is valid when adding new config
// - Single checkbox E.g. advertise_price_including_fees, additional_payment_methods
//   - Untouched required checkbox is valid
// - Drop down lists
//   - Non-required DDL can be cleared
//   - Required DDL has no initial value
//   - Pristine required DDL fails validation
// - Forms (Survey component)
//   - Validation errors
//   - Pristine required checkbox is valid
//   - String lists, strings, textarea, boolean/checkbox, options/DDL
// - Complex; Code editor
//   - Invalid syntax
//   - Does not pass schema validation
interface ConfigListProps {
  resource: ResourceName
  resource_id?: string
}

const props = defineProps<ConfigListProps>()
const resource = props.resource
const resourceId = getResourceID(resource, props.resource_id)

// Ignore templates.
// @ts-ignore Top-level await
const values = reactive(await getConfigValuesAndInitializeTemplates(resource, resourceId))

const showUnconfigured = ref(true)
const showValues = ref(true)

const query = ref('')

type Groups = Dict<Omit<ConfigItemProps, 'showValue'>[]>

const groups = computed<Groups>(() => {
  const result: Groups = {}
  const templates = getMatchingTemplates(props.resource, query.value)
  for (const template of templates) {
    const path = configItemPath(template)
    const value = values[path]
    if (value || showUnconfigured.value) {
      result[template.namespace] ??= []
      result[template.namespace].push({path, resourceId, template, value})
    }
  }
  return result
})
</script>

<template>
  <div class="Settings21">
    <div class="Settings21__Heading">
      <h2>Configuration</h2>
    </div>

    <div class="Settings21__SearchInput">
      <div class="SearchInput">
        <input type="search" v-model="query" placeholder="Search for a configuration option" />
      </div>
      <div class="filters">
        <label class="filter">
          <input type="checkbox" v-model="showUnconfigured" />
          Show unconfigured options
        </label>
        <label class="filter">
          <input type="checkbox" v-model="showValues" />
          Show values
        </label>
      </div>
    </div>

    <details v-for="(group, name) in groups" :key="name" open class="group">
      <summary>
        <span class="grow">{{ name }}</span>
      </summary>
      <div class="list">
        <ConfigListItem v-for="item in group" :key="item.path" v-model="values[item.path]" @deleted="values[item.path] = undefined" v-bind="item" :showValue="showValues" class="item" />
      </div>
    </details>
  </div>
</template>

<style scoped lang="scss">
.Settings21__SearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .SearchInput {
    flex-basis: 50%;
    input:focus {
      border-color: #bfc1ca;
    }
  }
  .filters {
    flex-basis: content;
    .filter input[type='checkbox'] {
      width: auto;
    }
  }
}

details.group {
  border-top: 1px solid $separator-colour;
  > summary {
    font-weight: 600;
    font-size: 16px;
    color: $kind-of-black;
    padding: 16px;
  }
}

.list {
  background: $ticket-group-body-colour;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  display: none;
}

details {
  > summary {
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;

    .grow {
      flex-grow: 1;
    }

    &::-webkit-details-marker,
    &::marker {
      display: none;
      // Firefox 🤷
      font-size: 0;
    }

    &:after {
      $arrow-size: 8px;
      content: '';
      display: inline-block;
      flex: 0 0 $arrow-size;
      height: $arrow-size;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: black;
      transform: rotate(180deg - 45deg);
      transform-origin: 2/3 1/3;
      transition: 0.25s transform ease;
    }
  }

  &[open] > summary {
    &:after {
      transform: rotate(360deg - 45deg);
    }
  }

  &[open] > .list {
    display: flex;
  }
}
</style>

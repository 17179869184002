import {createApp, ref} from 'vue'
import App from './App.vue'
import {router} from './router/Router'
import {synchronizeRouters} from './router/synchronizer'
import {getInitialPromise} from '@/helpers/api'
import {isAuthenticated} from '@/helpers/access'
import {OktaAuth} from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import {install as VueMonacoEditorPlugin} from '@guolao/vue-monaco-editor'
import {logInTrackJS} from '@/helpers/errors'
import sanitizeHtml from 'sanitize-html'
import {sendMessageToCMSNext} from '@/helpers/CMSNext'
import msalPlugin from '@/helpers/msalPlugin'
import {globalHandler} from '@/api/error/globalHandler'

// For now (until the CMS core is rewritten in Vue3),
// we don't want to mount the Vue3 app unless the Vue1 one is authenticated (user is logged in).

let vue3App = null

declare let theApp

const authenticated = ref(false)
const authorised = ref(null)
// The previous value of the "authorised" variable. Used to decide whether there is a previous page to go to.
const prevAuthorised = ref(null)
const notFound = ref(false)

const mountVue3App = () => {
  if (vue3App === null) {
    vue3App = createApp(App, {authenticated, authorised, prevAuthorised, notFound})
    vue3App.config.errorHandler = (err, instance, info) => {
      if (!globalHandler(err)) {
        logInTrackJS(info, err)
      }
    }

    vue3App.directive('autofocus', (el, {value}) => {
      if (value) {
        el.focus()
      }
    })
    vue3App.directive('shtml', (el, binding) => {
      el.innerHTML = sanitizeHtml(binding.value)
    })
    const sso = window.TIX.sso?.okta_staff_sso
    if (sso) {
      try {
        const oktaAuth = new OktaAuth({
          issuer: sso.issuer_url,
          clientId: sso.clientId,
          redirectUri: window.location.origin + '/okta/callback',
          scopes: ['openid', 'profile', 'email']
        })

        vue3App.use(OktaVue, {oktaAuth})
      } catch (e) {
        window._initialisationError = e.message
        logInTrackJS(e.message, null)
      }
    }

    const entra = window.TIX.sso?.entra_staff_sso
    if (entra) {
      vue3App.use(msalPlugin, {
        clientId: entra.clientId,
        issuerUrl: entra.issuer_url,
        redirectUrl: window.location.origin + '/entra/callback'
      })
    }

    vue3App.use(VueMonacoEditorPlugin, {
      paths: {
        // The recommended CDN config
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
      }
    })
    vue3App.use(router).mount('#app')
  }
}

const updateAuthenticationState = () => {
  authenticated.value = isAuthenticated()
}

theApp.$on('authenticationchange', updateAuthenticationState)
theApp.$on('authorisationchange', v => {
  if (authorised.value !== null) {
    prevAuthorised.value = authorised.value
  }
  authorised.value = v
})

router.beforeEach(to => {
  sendMessageToCMSNext({route: to.fullPath, title: document.title})

  notFound.value = theApp.isNotFound
})

router.afterEach(() => globalThis.scrollTo({top: 0, left: 0}))

theApp.$on('notfound', () => {
  notFound.value = true
})

getInitialPromise().then(() => {
  updateAuthenticationState()
  mountVue3App()
})

synchronizeRouters(window.Router1)

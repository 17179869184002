<script setup lang="ts">
// @cms-next src/components/account/LoginForm
import {computed, nextTick, onMounted, Ref, ref, watch} from 'vue'
import EmailRegexp from '@old/services/EmailRegexp'
import {maybeGoToTarget} from '@/helpers/route'
import {AuthMethod, login, ssoLogin} from '@/helpers/api'
import {useAuth} from '@okta/okta-vue'
// @ts-ignore
import oktaLogoUrl from '@/styles/images/okta.svg'
// @ts-ignore
import entraLogoUrl from '@/styles/images/entra.svg'
// @ts-ignore
import ticketureLogoUrl from '@/styles/images/ticketure.svg'
import {runPostLoginTask} from '@/helpers/login'
import {getConfigAuthMethods} from '@/helpers/access'
import {logInTrackJS} from '@/helpers/errors'
import {useMsalAuth} from '@/helpers/useMsalAuth'

const props = defineProps({
  isSsoSupported: {
    type: Boolean,
    default: true
  },
  error: {
    type: String,
    default: ''
  }
})

const internalError = ref('')
const success = ref('')
const credentials = ref({email: '', password: ''})
const forgotPassMode = ref(false)
const emailInput = ref()
const submitButton = ref()
const loginMode: Ref<AuthMethod | null> = ref(sessionStorage.getItem('loginMode') as AuthMethod)
const enabledAuthMethods: AuthMethod[] = getConfigAuthMethods()
let oktaAuth = null
try {
  oktaAuth = useAuth()
} catch (e) {
  // Intentionally ignored
  // The okta instantiation error handling in `new-main.ts` also covers this
}

const msalAuth = useMsalAuth()

if (window._initialisationError) {
  internalError.value = window._initialisationError
  delete window._initialisationError
}

const emit = defineEmits(['loginModeChange', 'forgotPasswordModeToggle'])
// This needs to come before the setting of initial login mode
watch(loginMode, v => {
  emit('loginModeChange', v)
  sessionStorage.setItem('loginMode', v)
})

// Reset login mode based on the login mode priority if:
if (
  // the login mode is not set or
  !loginMode.value ||
  // the mode is set to okta but there's no okta auth instantiated
  (loginMode.value === 'okta_staff' && !oktaAuth) ||
  // the mode is set to okta but there's no entra auth instantiated
  (loginMode.value === 'entra_staff' && !msalAuth)
) {
  const loginModePriority: AuthMethod[] = ['ticketure', 'entra_staff', 'okta_staff', 'ticketure_staff']
  for (const mode of loginModePriority) {
    if (enabledAuthMethods.includes(mode)) {
      loginMode.value = mode
      break
    }
  }
}

// If the login mode is okta and there's okta token, then attempt to log in
if (oktaAuth && loginMode.value === 'okta_staff') {
  oktaAuth.tokenManager
    .getTokens()
    .then(tokens => {
      const idToken = tokens.idToken?.idToken
      if (idToken) {
        const ui = document.querySelector('.btn-okta') as HTMLButtonElement
        ssoLogin(idToken, 'okta_staff', ui)
          .then(() => runPostLoginTask(true, ui))
          .catch(error => {
            internalError.value = error
            oktaAuth.clearStorage()
          })
      }
    })
    .catch(error => {
      logInTrackJS('Okta `getTokens()` error', error)
    })
}

const focusInput = () => nextTick(() => emailInput.value.focus())
const linkClick = () => {
  internalError.value = ''
  success.value = ''
  forgotPassMode.value = !forgotPassMode.value
  focusInput()
  emit('forgotPasswordModeToggle')
}

const header = computed(() => {
  if (forgotPassMode.value) {
    return 'Forgot your password?'
  }

  return loginMode.value === 'ticketure' ? 'Legacy log in' : 'Log in'
})
const note = computed(() => {
  if (loginMode.value === 'okta_staff') {
    return 'Use Okta’s single sign-on (SSO) to log in to your account.'
  }

  return forgotPassMode.value ? "Enter the email address associated with your account and we'll send you a link to reset your password." : 'Enter your credentials below to log in to your account.'
})
const btn = computed(() => (forgotPassMode.value ? 'Send a Reset Password Email' : 'Log In'))
const link = computed(() => (forgotPassMode.value ? '← Back to login' : 'Forgot password?'))

onMounted(async () => {
  if (msalAuth) {
    try {
      await msalAuth.initialize()
      if (loginMode.value === 'entra_staff') {
        const user = await msalAuth.handleRedirect()
        if (user) {
          const ui = document.querySelector('.btn-entra') as HTMLButtonElement
          await ssoLogin(user.idToken, 'entra_staff', ui)
          runPostLoginTask(true, ui)
        }
      }
    } catch (e) {
      internalError.value = e.message
      // It is probably unlikely that the message property will ever be missing, but account for such a possibility anyway:
      if (!e.message || !e.message.includes('error 401')) {
        // Do not log mere authentication failures
        logInTrackJS(e.message ?? 'Error with no message', null)
      }
    }
  }

  if (loginMode.value !== 'okta_staff' && loginMode.value !== 'entra_staff') {
    focusInput()
  }
})

const displayError = computed(() => {
  return internalError.value !== '' ? internalError.value : props.error
})

const onSubmit = () => {
  if (!EmailRegexp.test(credentials.value.email) || (!forgotPassMode && credentials.value.password.length === 0)) {
    internalError.value = 'Please enter a valid email address'
    if (!forgotPassMode.value) {
      internalError.value += ' and password'
    }
    return false
  }
  const ui = submitButton.value
  const creds = window.dClone(credentials.value)
  credentials.value.email = ''
  credentials.value.password = ''
  internalError.value = ''
  delete window.sellersPromise
  loginOrForgotPassword(creds, ui).catch(e => {
    internalError.value = e.isNotAuthorisedAPIError ? 'The email or password you entered is incorrect. Please try again.' : e
  })
  return true
}

function loginOrForgotPassword(creds, ui): Promise<any> {
  return forgotPassMode.value
    ? window.APIService.post(
        'password_reset/request',
        {
          email: creds.email,
          provider: loginMode.value === 'ticketure' ? 'tix' : 'staff'
        },
        {ui}
      ).then(() => {
        success.value = 'If you have an account, you will receive an email in the next ' + '15 minutes with instructions on resetting your password.'
      })
    : login(creds.email, creds.password, loginMode.value, ui).then(() => {
        runPostLoginTask(!maybeGoToTarget(), ui)
      })
}

const loginWithOkta = async () => {
  await oktaAuth.signInWithRedirect()
}

const loginWithEntra = async () => {
  await msalAuth.login()
}

const shouldShowToggleLinkFor = (mode: AuthMethod) => {
  const enabled = enabledAuthMethods.includes(mode)

  if (mode === 'okta_staff' && (!oktaAuth || msalAuth)) {
    return false
  }

  if (mode === 'entra_staff' && !msalAuth) {
    return false
  }

  return enabled && loginMode.value !== mode
}
</script>

<template>
  <form novalidate @submit.prevent="onSubmit">
    <h1>{{ header }}</h1>
    <p>{{ note }}</p>
    <div v-if="displayError" class="WarningBlockRed">{{ displayError }}</div>

    <button href="#" v-if="loginMode === 'okta_staff' && isSsoSupported" @click.prevent="loginWithOkta" class="btn btn-primary btn-okta uppercase" data-submit-spinner="1"><img :src="oktaLogoUrl" alt="Okta" /> Continue with Okta</button>
    <button href="#" v-else-if="loginMode === 'entra_staff' && isSsoSupported" @click.prevent="loginWithEntra" class="btn btn-primary btn-entra uppercase" data-submit-spinner="1"><img :src="entraLogoUrl" alt="Entra" /> Continue with Entra</button>
    <template v-else>
      <div>
        <label for="email">Email</label>
        <input id="email" ref="emailInput" v-model="credentials.email" name="email" type="email" autofocus placeholder="eg. john.smith@website.com" tabindex="1" />
      </div>
      <div v-if="!forgotPassMode">
        <label for="password">Password</label>
        <input v-model="credentials.password" id="password" name="password" type="password" tabindex="2" />
      </div>
      <a tabindex="4" @click.prevent="linkClick" v-if="!forgotPassMode">{{ link }}</a>
      <button ref="submitButton" class="btn btn-primary uppercase" tabindex="3" id="LoginButton" data-submit-ui-disabled="1" data-submit-spinner="1">{{ btn }}</button>
      <div v-if="success" class="InfoBlock">{{ success }}</div>
      <a tabindex="4" @click.prevent="linkClick" v-if="forgotPassMode" ref="submitButton">{{ link }}</a>
    </template>

    <div class="LoginModeToggle text-center" v-if="!forgotPassMode && enabledAuthMethods.length > 1">
      <h2 class="LoginModeToggle__Heading"><span>Or log in with your</span></h2>

      <button class="btn btn-primary btn-new-staff" v-if="shouldShowToggleLinkFor('ticketure_staff')" @click.prevent="loginMode = 'ticketure_staff'"><img :src="ticketureLogoUrl" alt="Ticketure logo" /> New staff Account Login</button>
      <button class="btn btn-primary btn-legacy" v-if="shouldShowToggleLinkFor('ticketure')" @click.prevent="loginMode = 'ticketure'"><img :src="ticketureLogoUrl" alt="Ticketure logo" /> Legacy staff Account Login</button>
      <button class="btn btn-primary btn-okta" v-if="shouldShowToggleLinkFor('okta_staff')" @click.prevent="loginMode = 'okta_staff'"><img :src="oktaLogoUrl" alt="Okta logo" /> Okta single sign-on (SSO)</button>
      <button class="btn btn-primary btn-entra" v-if="shouldShowToggleLinkFor('entra_staff')" @click.prevent="loginMode = 'entra_staff'"><img :src="entraLogoUrl" alt="Entra logo" /> Entra single sign-on (SSO)</button>
    </div>
  </form>
</template>

<style lang="scss">
.btn-okta {
  img {
    height: 16px;
    vertical-align: middle;
    margin-top: -4px;
    display: inline-block;
  }
}

.LoginModeToggle {
  &__Heading {
    font-size: 14px;
    font-weight: 400;
    margin: 40px 0 32px 0;

    span {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      z-index: 1;
      background-color: var(--unauth-bg-color);
    }

    &::after {
      position: relative;
      top: -9px;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.2;
    }
  }

  button {
    margin-bottom: 0;
    color: #fff;

    &:hover {
      color: #ddd;
    }
  }
}
</style>

<script lang="ts" setup>
import {computed, Ref, ref, watch} from 'vue'

import {getUnAuthPageAttrs, unAuthFormBgColor, altUnAuthFormBgColor} from '@/helpers/ui'
import LoginForm from '@/components/LoginForm.vue'
import ResetPasswordForm from '@/components/ResetPasswordForm.vue'
import {getQuery} from '@/helpers/route'
import {AuthMethod, checkResetPasswordToken, ssoLogin} from '@/helpers/api'
import {useAuth} from '@okta/okta-vue'
import {isSsoSupported as hasSso} from '@/helpers/access'

type UserType = 'staff' | 'tix'

const {bgImage} = getUnAuthPageAttrs()

const unauthMode: Ref<undefined | 'login' | 'confirm-account' | 'reset-password' | 'okta-callback' | 'okta-logout'> = ref()

const error = ref('')
const resetPasswordEmail = ref('')
const resetPasswordUserType: Ref<UserType> = ref('staff' as UserType)
const isSsoSupported = hasSso()

const isNewStaffLogin = ref(false)
const onLoginModeChange = (mode: AuthMethod) => {
  isNewStaffLogin.value = mode === 'ticketure_staff'
}

const onForgotPasswordModeToggle = () => {
  error.value = ''
}

const updateFormBgColor = () => {
  const {formBgColor} = getUnAuthPageAttrs(isNewStaffLogin.value ? altUnAuthFormBgColor : unAuthFormBgColor)
  document.body.style.setProperty('--unauth-bg-color', formBgColor)
}

watch(isNewStaffLogin, v => {
  updateFormBgColor()
})

updateFormBgColor()

const query = getQuery()
if (query['confirm-account'] != null) {
  unauthMode.value = 'confirm-account'
} else if (query['reset-password'] != null) {
  unauthMode.value = 'reset-password'
} else if (query['okta-callback'] != null) {
  unauthMode.value = 'okta-callback'
} else if (query['okta-logout'] != null) {
  unauthMode.value = 'okta-logout'
} else {
  unauthMode.value = 'login'
}

if (unauthMode.value === 'confirm-account' || unauthMode.value === 'reset-password') {
  checkResetPasswordToken(query['token'])
    .then(({email, provider}) => {
      resetPasswordEmail.value = email
      resetPasswordUserType.value = provider
    })
    .catch(err => {
      error.value = err
      unauthMode.value = 'login'
    })
} else if (unauthMode.value === 'okta-callback') {
  const auth = useAuth()
  auth.token.parseFromUrl().then(data => {
    const accessToken = data.tokens.accessToken.accessToken
    if (accessToken) {
      ssoLogin(accessToken, 'okta_staff')
    }
  })
}

const isPasswordMode = computed(() => resetPasswordEmail.value !== '' && (unauthMode.value === 'confirm-account' || unauthMode.value === 'reset-password'))
const isLoginMode = computed(() => unauthMode.value === 'login')
</script>

<template>
  <section class="UnauthView" :style="{backgroundImage: `url('${bgImage}')`}">
    <div class="UnauthView__Content">
      <div class="DesktopLogo"></div>
      <ResetPasswordForm v-if="isPasswordMode" :mode="unauthMode as 'confirm-account' | 'reset-password'" :userType="resetPasswordUserType" :token="query['token']" :email="resetPasswordEmail" />
      <LoginForm v-else-if="isLoginMode" :error="error" :is-sso-supported="isSsoSupported" @login-mode-change="onLoginModeChange" @forgot-password-mode-toggle="onForgotPasswordModeToggle"></LoginForm>
    </div>
  </section>
</template>

<style lang="scss">
.UnauthView__Content {
  background-color: var(--unauth-bg-color);
}
</style>
